export const contactsData = {
  address: "ул. Св. Св. Кирил и Методий №27 eтаж 4.",
  address2: "1202 София, Център, Оборище.",
  telephone: "088 6222565",
  telephoneMisho: "089 2222208",
  facebook: "https://www.facebook.com/MatemachitelBG/",
  linkedin: "https://www.linkedin.com/company/matemachitelbg/posts",
  instagram: "https://www.instagram.com/matemachitelbg/",
  facebookAltMessage: "link to Matemachitel's Facebook page",
  linkedinAltMessage: "link to Matemachitel's Linkedin page",
  instagramAltMessage: "link to Matemachitel's Instagram page"
};
