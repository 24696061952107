import React from 'react';
import styled from 'styled-components';import { useState } from "react";
import { RiArrowGoBackLine } from "react-icons/ri";

import {
  CardImageWrapper,
  ImageContent,
  Wrapper,
  Overlay,
  CardImage,
  CardContent,
  TeacherName,
  TeacherDescription,
  ViewMoreButton,
  AdditionalDescription,
  Price,
  LearnMoreViewWrapper,
} from "./cardElements";

const Card = ({ imgSrc, name, description, learnMoreInfo, key }) => {
  const [learnMoreView, setLearnMoreView] = useState(false);

  if (learnMoreView) {
    return (
      <LearnMoreViewWrapper key={key}>
        <AdditionalDescription>{learnMoreInfo.info}</AdditionalDescription>
        <Price>{learnMoreInfo.price}</Price>
        <ViewMoreButton onClick={() => setLearnMoreView(false)}>
          <RiArrowGoBackLine />
        </ViewMoreButton>
      </LearnMoreViewWrapper>
    );
  } else {
    return (
      <Wrapper key={key}>
        <ImageContent>
          <Overlay />
          <CardImageWrapper>
            <CardImage src={imgSrc} />
          </CardImageWrapper>
        </ImageContent>
        <CardContent>
          <TeacherName>{name}</TeacherName>
          <TeacherDescription>{description}</TeacherDescription>
          <ViewMoreButton onClick={() => setLearnMoreView(!learnMoreView)}>
            Разбери повече
          </ViewMoreButton>
        </CardContent>
      </Wrapper>
    );
  }
};

export default Card;
