import React from "react";
import Maps from "../Maps/maps";

import { GoLocation } from "react-icons/go";
import { BsTelephone } from "react-icons/bs";
import { FaFacebookSquare, FaLinkedin, FaInstagramSquare } from "react-icons/fa";
import {
  StyledContactsContainer,
  Info,
  Address,
  MobileNumberContent,
  PhoneNumber,
  MapsWrapper,
  ContactsWrapper,
  ContactsInfoItemWrapper,
  ContactsImageWrapper,
  StyledContactsImage,
  ContactData,
  FacebookLink
} from "./contactsElements";
import { Title } from "../Teachers/teachersElements";

import ContactUsImg from "../../assets/photos/draw_ppl.png";
import { contactsData } from "../../assets/data/contactsInfo";

const Contacts = () => {
  return (
    <StyledContactsContainer id={"contacts"}>
      <Info>
        <Title>Контакти</Title>
        <ContactsWrapper>
          <ContactsInfoItemWrapper>
            <GoLocation size={20}/>
            <ContactData>{contactsData.address}</ContactData>
          </ContactsInfoItemWrapper>
          <ContactsInfoItemWrapper>
            <ContactData>{contactsData.address2}</ContactData>
          </ContactsInfoItemWrapper>
          <ContactsInfoItemWrapper>
            <BsTelephone size={20} />
            <ContactData>{contactsData.telephone}</ContactData>
          </ContactsInfoItemWrapper>
          <ContactsInfoItemWrapper>
            <BsTelephone size={20} />
            <ContactData>{contactsData.telephoneMisho}</ContactData>
          </ContactsInfoItemWrapper>
          <ContactsInfoItemWrapper>
            <FaFacebookSquare size={24} />
            <ContactData>
              <FacebookLink
                href={contactsData.facebook}
                alt={contactsData.facebookAltMessage}
              >
                MatemachitelBG
              </FacebookLink>
            </ContactData>
          </ContactsInfoItemWrapper>
          <ContactsInfoItemWrapper>
            <FaLinkedin size={24} />
            <ContactData>
              <FacebookLink
                href={contactsData.linkedin}
                alt={contactsData.linkedinAltMessage}
              >
                MatemachitelBG
              </FacebookLink>
            </ContactData>
          </ContactsInfoItemWrapper>
          <ContactsInfoItemWrapper>
            <FaInstagramSquare size={24} />
            <ContactData>
              <FacebookLink
                href={contactsData.instagram}
                alt={contactsData.instagramAltMessage}
              >
                MatemachitelBG
              </FacebookLink>
            </ContactData>
          </ContactsInfoItemWrapper>
        </ContactsWrapper>
        {/* <StyledContactsImage src={ContactUsImg} /> */}
      </Info>
      <MapsWrapper>
          <Maps />
        </MapsWrapper>
    </StyledContactsContainer>
  );
};

export default Contacts;
