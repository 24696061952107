import React from 'react';
import styled from "styled-components/macro";
import { PAGE_FONTS, WINDOW_WIDTH_CONSTRAINTS } from "../../constants";
import { TeachersDescription1, Title } from '../Teachers/teachersElements';

const FONT_COLOR = '#19173C';
const TEXT_COLOR = 'white';

const StyledTimetableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;

  @media (max-width: ${WINDOW_WIDTH_CONSTRAINTS.SMALL_SCREENS_DOWN_LIMIT}) {
    margin: 10px;
  }
`;

const CalendarContainer = styled.div`
  margin: 20px;
  display: flex;
  justify-content: center;
  width: 100%;

  @media (max-width: ${WINDOW_WIDTH_CONSTRAINTS.SMALL_SCREENS_DOWN_LIMIT}) {
    margin: 10px;
  }
`;

const TimetableTable = styled.table`
  width: 80%;
  border-collapse: collapse;
  border-spacing: 0;

  @media (max-width: ${WINDOW_WIDTH_CONSTRAINTS.MEDIUM_SCREENS_DOWN_LIMIT}) {
    width: 90%;
  }

  @media (max-width: ${WINDOW_WIDTH_CONSTRAINTS.SMALL_SCREENS_DOWN_LIMIT}) {
    width: 100%;
  }
`;

const TableHeader = styled.th`
  border: 1px solid #ccc;
  padding: 10px;
  background-color: ${FONT_COLOR};
  text-align: center;
  position: sticky;
  top: 0;
  font-size: 20px;
  color: ${TEXT_COLOR};

  @media (max-width: ${WINDOW_WIDTH_CONSTRAINTS.SMALL_SCREENS_DOWN_LIMIT}) {
    font-size: 12px;
    padding: 3px;
  }

  @media (max-width: ${WINDOW_WIDTH_CONSTRAINTS.MEDIUM_SCREENS_DOWN_LIMIT}) {
    font-size: 16px;
    padding: 5px;
  }
`;

const TimeSlotCell = styled.td`
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  width: 10%;
  background-color: ${FONT_COLOR};
  color: ${TEXT_COLOR};
  font-family: ${PAGE_FONTS.OSWALD_FONT};
`;

const EventCell = styled.td`
  border: 1px solid #ccc;
  padding: 0;
  text-align: center;
  vertical-align: top;
  width: 45%;
  height: 30px;
`;

const EventDiv = styled.div`
  background-color: #CBC3F7;
  color: #19173C;
  height: 100%;
  padding: 5px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
  box-sizing: border-box;
  border: 1px solid #19173C;

  @media (max-width: ${WINDOW_WIDTH_CONSTRAINTS.SMALL_SCREENS_DOWN_LIMIT}) {
    font-size: 10px;
    padding: 3px;
  }

  .event-name {
    font-weight: bold;
    font: ${PAGE_FONTS.NUNITO_FONT};
    font-size: 16px;
  }

  .event-time,
  .event-teacher {
    font: ${PAGE_FONTS.NUNITO_FONT};
    font-size: 16px;
  }
`;

export const CalendarDescription = styled.p`
  font-family: ${PAGE_FONTS.NUNITO_FONT};
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: ${FONT_COLOR};
  line-height: 22px;
  text-align: center;
  margin: 50px 50px;

  @media screen and (max-width: ${WINDOW_WIDTH_CONSTRAINTS.MEDIUM_SCREENS_DOWN_LIMIT}) {
    font-size: 12px;
    max-width: 100%;
    margin: 20px 20px;
  }
`;

const Timetable = () => {
  const eventsBigGroup = [
    {
      day: 'Неделя',
      startTime: '10:00',
      endTime: '11:30',
      name: 'Състезателна математика 3 - 4-ти клас',
      teacher: 'Михаил Тодоров',
    },
    {
      day: 'Неделя',
      startTime: '12:00',
      endTime: '13:30',
      name: 'Училищна математика 5-ти клас',
      teacher: 'Николена Пейчева',
    },
    {
      day: 'Неделя',
      startTime: '14:00',
      endTime: '16:30',
      name: 'Училищна математика 7-ми клас',
      teacher: 'Николена Пейчева',
    },
    {
      day: 'Неделя',
      startTime: '16:30',
      endTime: '18:00',
      name: 'Училищна математика 6-ти клас',
      teacher: 'Николена Пейчева',
    },
    {
      day: 'Събота',
      startTime: '12:00',
      endTime: '14:00',
      name: 'Училищна математика 9-ти клас',
      teacher: 'Михаил Тодоров',
    },
    {
      day: 'Събота',
      startTime: '14:30',
      endTime: '17:00',
      name: 'Училищна математика 10-ти клас',
      teacher: 'Теодор Илиев',
    },
  ];

  const eventsSmallGroup = [
    {
      day: 'Събота',
      startTime: '10:00',
      endTime: '11:30',
      name: 'Състезателна математика 3 - 4-ти клас',
      teacher: 'Михаил Тодоров',
    },
    {
      day: 'Събота',
      startTime: '12:00',
      endTime: '13:30',
      name: 'Училищна математика 5-ти клас',
      teacher: 'Николена Пейчева',
    },
    {
      day: 'Събота',
      startTime: '14:00',
      endTime: '16:30',
      name: 'Училищна математика 7-ми клас',
      teacher: 'Николена Пейчева',
    },
    {
      day: 'Събота',
      startTime: '16:30',
      endTime: '18:00',
      name: 'Училищна математика 6-ти клас',
      teacher: 'Николена Пейчева',
    },
    {
      day: 'Неделя',
      startTime: '12:00',
      endTime: '14:00',
      name: 'Училищна математика 9-ти клас',
      teacher: 'Михаил Тодоров',
    },
    {
      day: 'Събота',
      startTime: '14:30',
      endTime: '17:00',
      name: 'Училищна математика 10-ти клас',
      teacher: 'Теодор Илиев',
    },
  ];

  const timeSlots = [
    '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30',
    '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30',
  ];

  const hours = ['10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00'];

  const calculateRowSpan = (startTime, endTime) => {
    const start = new Date(`1970-01-01T${startTime}:00`);
    const end = new Date(`1970-01-01T${endTime}:00`);
    const durationInMinutes = (end - start) / (1000 * 60);
    return Math.ceil(durationInMinutes / 30); // each row represents 30 minutes
  };
  
  const renderEvents = (day, time, events) => {
    const eventForThisTime = events.find(event => event.day === day && event.startTime === time);
  
    if (eventForThisTime) {
      const rowSpan = calculateRowSpan(eventForThisTime.startTime, eventForThisTime.endTime);
  
      return (
        <EventCell key={eventForThisTime.name} rowSpan={rowSpan}>
          <EventDiv>
            <div className="event-name">{eventForThisTime.name}</div>
            <div className="event-time">{eventForThisTime.startTime} - {eventForThisTime.endTime}</div>
            <div className="event-teacher">Преподавател: {eventForThisTime.teacher}</div>
          </EventDiv>
        </EventCell>
      );
    }
  
    // Check if the current time is within an ongoing event in the same column
    const isTimeWithinEvent = events.some(event => {
      const start = new Date(`1970-01-01T${event.startTime}:00`);
      const end = new Date(`1970-01-01T${event.endTime}:00`);
      const currentTime = new Date(`1970-01-01T${time}:00`);
      return event.day === day && currentTime > start && currentTime < end;
    });
  
    // If the time is within an event, skip rendering for this cell
    if (isTimeWithinEvent) {
      return null;
    }
  
    return <EventCell />;
  };
  
  return (
    <StyledTimetableContainer id={"timetable"}>
        <div style={{ width: '100%', height: '20px', backgroundColor: '#CBC3F7', marginBottom: '20px' }}></div>
      <Title>График за групови занятия</Title>
      <CalendarDescription>
        Тук можете да намерите нашия ориентировъчен график за групови занятия. 
        За повече информация или за сформиране на група по различно време може да се свържете с нас по телефона.
      </CalendarDescription>

      <CalendarDescription>График за групи до 6 ученика</CalendarDescription>
      <CalendarContainer>
        <TimetableTable>
          <thead>
            <tr>
              <TableHeader>Час</TableHeader>
              <TableHeader>Събота</TableHeader>
              <TableHeader>Неделя</TableHeader>
            </tr>
          </thead>
          <tbody>
            {timeSlots.map((time, index) => (
              <tr key={time}>
                {index % 2 === 0 && (
                  <TimeSlotCell rowSpan={2}>
                    {hours[Math.floor(index / 2)]}
                  </TimeSlotCell>
                )}
                {renderEvents('Събота', time, eventsBigGroup)}
                {renderEvents('Неделя', time,  eventsBigGroup)}
              </tr>
            ))}
          </tbody>
        </TimetableTable>
      </CalendarContainer>

      <CalendarDescription>График за групи до 3 ученика</CalendarDescription>
      <CalendarContainer>
        <TimetableTable>
          <thead>
            <tr>
              <TableHeader>Час</TableHeader>
              <TableHeader>Събота</TableHeader>
              <TableHeader>Неделя</TableHeader>
            </tr>
          </thead>
          <tbody>
            {timeSlots.map((time, index) => (
              <tr key={time}>
                {index % 2 === 0 && (
                  <TimeSlotCell rowSpan={2}>
                    {hours[Math.floor(index / 2)]}
                  </TimeSlotCell>
                )}
                {renderEvents('Събота', time, eventsSmallGroup)}
                {renderEvents('Неделя', time, eventsSmallGroup)}
              </tr>
            ))}
          </tbody>
        </TimetableTable>
      </CalendarContainer>
      
    </StyledTimetableContainer>
  );
};

export default Timetable;
