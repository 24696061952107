import React from "react";
import styled from "styled-components/macro";

import MaskGroup from "../../assets/photos/mask_group.png";
import { PAGE_FONTS, WINDOW_WIDTH_CONSTRAINTS } from "../../constants";
import { Link as LinkS } from "react-scroll";
import {
  HomePageWrapper,
  Heading,
  HomeImageWrapper,
  StyledHomeImage,
  Subtitle,
  LearnMoreButton,
  HomeInfoWrapper,
} from "./homePageElements";
import { InfoWrappper } from "../AboutUs/aboutUsElements";

// const StyledImage = styled.img`
//   /* box-sizing: border-box; */

//   height: calc(100vh - 60px);
//   width: 100%;

//   align-self: center;

//   /* background-position: center center; */
//   /* background-attachment: fixed; */
//   /* background-repeat: no-repeat; */
//   /* background-size: cover; */

//   /* filter: blur(2px);
//   -webkit-filter: blur(2px); */
//   border-image-width: auto;

//   /* @media screen and (max-width: ${WINDOW_WIDTH_CONSTRAINTS.SMALL_SCREENS_DOWN_LIMIT}) {
//     src: url(${AnimatedIllustration});
//   }

//   @media screen and (min-width: ${WINDOW_WIDTH_CONSTRAINTS.SMALL_SCREENS_UP_LIMIT}) and (max-width: ${WINDOW_WIDTH_CONSTRAINTS.MEDIUM_SCREENS_DOWN_LIMIT}) {
//     src: url(${AnimatedIllustration});
//   }
//   @media screen and (min-width: ${WINDOW_WIDTH_CONSTRAINTS.LARGE_SCREENS_UP_LIMIT}) {
//     src: url(${AnimatedIllustration});
//   } */
// `;
// const Title = styled.div`
//   color: #2b2e35;
//   font-weight: 400;
//   font-size: 60px;
//   font-family: ${PAGE_FONTS.TITLE_FONT};
//   position: absolute;
//   justify-content: left;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   padding: 20px;

//   @media screen and (max-width: ${WINDOW_WIDTH_CONSTRAINTS.MEDIUM_SCREENS_DOWN_LIMIT}) {
//     font-size: 40px;
//   }

//   @media screen and (max-width: ${WINDOW_WIDTH_CONSTRAINTS.WIDTH_SMALL_SCREENS_DOWN_LIMIT}) {
//     font-size: 32px;
//   }
// `;

// const ImageWrapper = styled.div`
//   box-sizing: border-box;
//   /* position: relative; */
//   height: calc(100vh - 60px);
//   /* max-width: 555px; */
//   background-color: #f0f0f0;
//   display: grid;
//   justify-content: center;
//   align-items: center;

//   grid-auto-columns: minmax(auto, 1fr);
//   grid-template-areas: ${({ imgStart }) =>
//     imgStart ? `'col2 col1'` : `'col1 col2'`};
//   grid-row-end: auto;

//   @media screen and (max-width: ${WINDOW_WIDTH_CONSTRAINTS.MEDIUM_SCREENS_DOWN_LIMIT}) {
//     grid-template-areas: ${({ imgStart }) =>
//       imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
//   } ;
// `;

const Col1 = styled.div`
  padding: 0 15px;
  grid-area: col1;
`;

const Col2 = styled.div`
  background-color: #f9f8f4;
  grid-area: col2;
`;

// export const LearnMoreButton = styled(LinkS)`
//   font-family: ${PAGE_FONTS.CARD_FONT};
//   font-size: 14px;

//   padding: 20px;

//   color: #fff;
//   /* padding: 8px 16px; */
//   background-color: #fb5357;
//   border: 1px solid #ffffff;
//   border-radius: 19px;
//   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
//   margin: 14px;

//   cursor: pointer;
//   transition: all 0.3s ease;

//   :hover {
//     background: #aee1cd;
//   }
// `;

// <ImageWrapper imgStart={false}></ImageWrapper>

const Button = styled.button`
  font-family: ${PAGE_FONTS.CARD_FONT};
  font-size: 14px;

  color: #fff;
  background: #fb5357;

  border: 1px solid #ffffff;
  border-radius: 19px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: all 0.3s ease;

  padding: 20px;

  cursor: pointer;

  :hover {
    background: #aee1cd;
  }
`;
export const HomePage = () => {
  return (
    <HomePageWrapper>
      <HomeInfoWrapper>
        <Heading>
          Млади и талантливи учители, готови да помогнат на всеки
        </Heading>
        <Subtitle> Може да намерите най-подходящите частни или групови уроци при нас </Subtitle>
        <LearnMoreButton>
          <LinkS to="contacts" smooth={true}>
            Запиши се сега
          </LinkS>
        </LearnMoreButton>
      </HomeInfoWrapper>
      <HomeImageWrapper>
        <StyledHomeImage src={MaskGroup} />
      </HomeImageWrapper>
    </HomePageWrapper>
  );
};

export default HomePage;
