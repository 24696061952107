import styled from "styled-components/macro";
import { PAGE_FONTS, WINDOW_WIDTH_CONSTRAINTS } from "../../constants";
import Slider from "react-slick";

export const StyledTeachersContainer = styled.div`
  /* height: 80vh; */
  // this is just a test, the eight in the one above
  height: 90vh;

  position: relative;
  // TO DO : Chack what's happening here
  // so it's not hidden behind navbar - navbar z-index is 10, that's the problem

  background-color: #ffffff;

  //TO DO: hide navbar for smaller screens
  @media screen and (max-width: ${WINDOW_WIDTH_CONSTRAINTS.MEDIUM_SCREENS_DOWN_LIMIT}) {
    z-index: 2;
    padding-top: 50px;
  }

  // TO DO: show navbar for bigger screens
  @media screen and (min-width: ${WINDOW_WIDTH_CONSTRAINTS.MEDIUM_SCREENS_UP_LIMIT}) {
    z-index: 11;
    /* padding-top: 50px; */
  }
`;

export const Title = styled.div`
  margin: 0;
  padding-top: 40px;
  padding-bottom: 40px;

  display: flex;
  justify-content: center;

  font-family: ${PAGE_FONTS.OSWALD_FONT};
  font-weight: 400;
  font-style: normal;
  font-size: 38px;
  line-height: 28px;

  color: ${(props) => (props.isPrimary ? 'white': '#19173C')};

  @media screen and (max-width: ${WINDOW_WIDTH_CONSTRAINTS.MEDIUM_SCREENS_DOWN_LIMIT}) {
    font-size: 28px;
    padding-bottom: 20px;
    margin-bottom: 0px;
  }
`;

export const CardWrapper = styled.div`
  display: absolute;
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 10px 40px;
`;

export const SliderParent = styled.div`
  width: 70%;
  margin: 0 auto;
  /* margin-top: 50px; */
  padding: 10px 10px;
`;

export const StyledSlider = styled(Slider)`
  .slick-next,
  .slick-prev {
    width: 1vw;
    height: 2vw;
    cursor: pointer;

    top: 50%;
  }

  .slick-next:before,
  .slick-prev:before {
    color: #19173C;
  }

  .slick-slide > div {
    margin: 0 20px;
  }

  .slick-list {
    margin: 0 -10px;
  }
`;

export const TeachersDescription1 = styled.p`
  font-family: ${PAGE_FONTS.NUNITO_FONT};
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #0F0E25;

  line-height: 22px;
  text-align: center;
  margin: 100px 100px;

  @media screen and (max-width: ${WINDOW_WIDTH_CONSTRAINTS.MEDIUM_SCREENS_DOWN_LIMIT}) {
    font-size: 12px;
    max-width: 100%; /* Allow full width on smaller screens */
    margin: 20px 20px; /* Adjust the margins for small screens */
  }
`;

export const TeacherDescription2 = styled.p`
  font-family: ${PAGE_FONTS.NUNITO_FONT};

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;

  margin: 0 40px 40px 40px;

  color: #fe6d5c;

  @media screen and (max-width: ${WINDOW_WIDTH_CONSTRAINTS.MEDIUM_SCREENS_DOWN_LIMIT}) {
    font-size: 10px;
  }
`;
