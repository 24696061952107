import teo from "../photos/TeacherPortrets/teo4.jpg";
import dilqna from "../photos/TeacherPortrets/dilqna.png";
import nikolena from "../photos/TeacherPortrets/nikolena.png"
import ema from "../photos/TeacherPortrets/ema.png";
import viktor from "../photos/TeacherPortrets/viktor.png";
import misho from "../photos/TeacherPortrets/misho.jpg"
import anita from "../photos/TeacherPortrets/anita.jpg"


export const allTeachers = [
  {
    image: teo,
    name: "Теодор Илиев",
    description: "Математика, Информатика",
    learnMoreInfo: { info: "Теодор е съосновател на МатемачителБГ и работи като софтуерен инженер. Преподава вече 6 години, като се занимава със състезателна математика от втори клас. Завършил е СМГ немската паралелка. Теодор е готов да помогне на всеки, който изпитва затруднения по математика и информатика.", price: "30лв. / 45 мин." },
  },
  {
    image: misho,
    name: "Михаил Тодоров",
    description: "Математика",
    learnMoreInfo: { info: "Михаил е съосновател и учител по математика в МатемачителБГ, завършил средното си образование в СМГ и в Америка. Михаил притежава магистърска диплома по Инженерство от Университета в Глазгоу и е сред водещите дизайнери на енергийни системи за възобновяема енергия в България и Европа. Превръща в своя мисия да даде на най-талантливите ученици необходимите знания по математика и физика и да им помогне да ги превърнат в успех.", price: "30лв. / 45 мин." },
  },
  {
    image: nikolena,
    name: "Николена Пейчева",
    description: "Математика",
    learnMoreInfo: {
      info: "Николена е преподавател по математика в 107 СОУ. Завършила е Математика и информатика в направление Педагогика на обучението. Своя стаж е изкарала в СМГ Паисий Хилендарски. Разчита на съвременни методи за обучение, водейки се по индивидуалните потребности на всеки ученик. Основната ѝ цел е повишаване на мотивацията и надграждане на знанията на учениците.",
      price: "30лв. / 45 мин.",
    },
  },
  {
    image: anita,
    name: "Анита Кирова",
    description: "Математика",
    learnMoreInfo: {
      info: "",
      price: "25лв. / 45 мин.",
    },
  },
  {
    image: dilqna,
    name: "Диляна Топузова",
    description: "Математика",
    learnMoreInfo: {
      info: "Диляна е завършила СМГ „Паисий Хилендарски“ с отличие. Следва архитектура в УАСГ. Преподава математика вече четвърта година в школата на БГ Ментор. За нея математиката е като редицата на Фибоначи - всяка следваща стъпка е резултат от сбора на предходните две.",
      price: "25лв. / 45 мин.",
    },
  },
];
