import styled from "styled-components/macro";
import { PAGE_FONTS, WINDOW_WIDTH_CONSTRAINTS } from "../../constants";

export const StyledAboutUsContainer = styled.div`
  min-height: 100vh; /* Changed from height to min-height */

  background-color: #19173C;

  display: flex;
  align-items: center;

  position: relative;

  // hide navbar for smaller screens
  @media screen and (max-width: ${WINDOW_WIDTH_CONSTRAINTS.MEDIUM_SCREENS_DOWN_LIMIT}) {
    z-index: 2;
    padding-top: 50px;
    flex-direction: column-reverse;
  }

  //show navbar for bigger screens
  @media screen and (min-width: ${WINDOW_WIDTH_CONSTRAINTS.MEDIUM_SCREENS_UP_LIMIT}) {
    z-index: 11;
  }
`;

export const Description = styled.p`
  font-family: ${PAGE_FONTS.NUNITO_FONT};
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;

  color: white;

  padding: 25px 25px;

  text-align: center;

  @media screen and (max-width: ${WINDOW_WIDTH_CONSTRAINTS.MEDIUM_SCREENS_DOWN_LIMIT}) {
    font-size: 12px;
  }
`;

export const ImageWrapper = styled.div`
  width: 50%;
  height: 100%;

  display: flex;
  align-items: center;


  @media screen and (max-width: ${WINDOW_WIDTH_CONSTRAINTS.MEDIUM_SCREENS_DOWN_LIMIT}) {
  }
`;

export const InfoWrappper = styled.div`
  width: 100%;
  height: 100%;
`;

export const StyledImage = styled.img`
  width: 100%;

  @media screen and (max-width: ${WINDOW_WIDTH_CONSTRAINTS.MEDIUM_SCREENS_DOWN_LIMIT}) {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
  }
`;
