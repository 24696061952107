import styled from "styled-components/macro";

import { PAGE_FONTS, WINDOW_WIDTH_CONSTRAINTS } from "../../constants";

export const HomePageWrapper = styled.div`
  height: calc(100vh - 55px);

  background-color: #19173C;

  display: flex;

  @media screen and (max-width: ${WINDOW_WIDTH_CONSTRAINTS.MEDIUM_SCREENS_DOWN_LIMIT}) {
    // Even if the image is at the start of the page, position it after the description
    flex-direction: column;
    padding-top: 50px;
  }
`;

export const HomeInfoWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 20px;

  text-align: center;

  @media screen and (max-width: ${WINDOW_WIDTH_CONSTRAINTS.MEDIUM_SCREENS_DOWN_LIMIT}) {
    align-items: center;
    text-align: center;
  }
`;

export const Heading = styled.h1`
  color: white;
  font-weight: 400;
  font-size: 60px;
  font-family: ${PAGE_FONTS.OSWALD_FONT};

  text-align: center;

  @media screen and (max-width: ${WINDOW_WIDTH_CONSTRAINTS.MEDIUM_SCREENS_DOWN_LIMIT}) {
    font-size: 40px;
    text-align: center;
  }

  @media screen and (max-width: ${WINDOW_WIDTH_CONSTRAINTS.WIDTH_SMALL_SCREENS_DOWN_LIMIT}) {
    font-size: 32px;
    text-align: center;
  }
`;

export const Subtitle = styled.p`
  color: white;

  font-family: ${PAGE_FONTS.NUNITO_FONT};
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;

  text-align: center;

  @media screen and (max-width: ${WINDOW_WIDTH_CONSTRAINTS.MEDIUM_SCREENS_DOWN_LIMIT}) {
    font-size: 18px;
    text-align: center;
  }

  @media screen and (max-width: ${WINDOW_WIDTH_CONSTRAINTS.WIDTH_SMALL_SCREENS_DOWN_LIMIT}) {
    font-size: 14px;
    text-align: center;
  }
`;

export const LearnMoreButton = styled.button`
  font-family: ${PAGE_FONTS.OSWALD_FONT};
  font-size: 22px;

  color: #0F0E25;
  background-color: #D6F082;

  border: 1px solid #ffffff;
  border-radius: 19px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: all 0.3s ease;
  // button size grows based on this
  padding: 20px;

  // fixed width
  width: 180px;

  // distance from element above
  margin-top: 30px;

  cursor: pointer;

  :hover {
    background: white;
  }
`;

export const HomeImageWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #19173C;

  @media screen and (max-width: ${WINDOW_WIDTH_CONSTRAINTS.MEDIUM_SCREENS_DOWN_LIMIT}) {
    background-color: #19173C;
        min-height: auto;
  }
`;

export const StyledHomeImage = styled.img`
  width: 70%;
  max-width: 550px;
  min-width: 150px;

  @media screen and (max-width: ${WINDOW_WIDTH_CONSTRAINTS.LARGE_SCREENS_UP_LIMIT}) {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;

    width: 40%;
    height: auto;
  }
`;
