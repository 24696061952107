import React from "react";
import AboutUsImage from "../../assets/photos/tedo.png";
import TextFrame from "../../assets/photos/frame.png";

import {
  Description,
  StyledAboutUsContainer,
  ImageWrapper,
  InfoWrappper,
  StyledImage,
  TextFrameWrapper,
} from "./aboutUsElements";
import { Title } from "../Teachers/teachersElements";

const AboutUs = () => {
  return (
    <StyledAboutUsContainer id={"about"}>
      <ImageWrapper>
        <StyledImage src={AboutUsImage} />
      </ImageWrapper>
      <InfoWrappper>
        <Title isPrimary>За нас</Title>

        <Description>
          Нашият екип е съставен от млади преподаватели, специалисти в
          най-различни области (софтуер, инженерство, дизайн и др.) с успешни
          кариери и редица проспериращи бизнеси зад гърба си, които се стремят
          да покажат с личен пример на учениците, че математика е най-краткият
          път към успеха без значение от конкретните интересите на детето.
          Учителите на МатемачителБГ, бивши възпитаници на СМГ, завършили в
          най-добрите университети в Европа, са превърнали в своя мисия да
          станат ментори на учениците си, посредством уроци по математика,
          програмиране и физика както онлайн, така и на живо. Ние се стараем да
          преподаваме дисциплините в тяхната цялост, осъществявайки логическите
          връзки за разбиране на материалът в дълбочина. По този начин материята
          се усвоява, а това от своя страна позволява на децата да прилагат
          математиката в училище, университета и навън. Подходът ни преодолява
          често срещаната бариера учител-ученик, а учителите ни показват на
          децата ползите от любопитството и знанието. Опитът ни подсказва, че
          именно този подход изгражда доверие базирано на общи цели и вяра в
          ползите на добре усвоената математика, подкрепено от страхотна
          комуникация и честа обратна връзка.
        </Description>

        <Description>
          Ние винаги се стараем да преподаваме не само конвенционално (както в
          училище), а осъществявайки логически връзки, което води до разбиране
          на материала в дълбочина. По този начин материята се усвоява, а не се
          наизустява. Нашият разчупен подход и много общи теми ни сближават с
          учениците. Изграждаме доверие и комуникацията е значително по-лесна,
          което предразполага учениците да задават въпроси. Така ние
          преодоляваме най-често срещаната бариера между учител и ученик, защото
          тази бариера възпрепятства именно изчистването на пропуските им.
        </Description>
      </InfoWrappper>
    </StyledAboutUsContainer>
  );
};

export default AboutUs;
